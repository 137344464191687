import { makeStyles } from '@material-ui/core/styles'
import Ad from './Ad'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#ad-billboard, .ad-billboard': {
      display: 'inline-block',
      maxWidth: '1232px',
      width: '100%',
      height: '280px',
      '@media (max-width: 500px)': {
        height: '312px'
      }
    }
  }
}))

export default function BillboardAd ({ slot, className }) {
  /*eslint-disable */
  // required to import class for Google Ad
  const classes = useStyles()

  const isActive = () => {
    const adSenseEnabled = process.env.NEXT_PUBLIC_ADSENSE_ENABLED === 'true'
    return adSenseEnabled && slot
  }

  if (!isActive()) {
    return ''
  }

  return (
    <div id='ad-billboard' className={className}>
      <Ad slot={slot} classname={'ad-billboard'} fullWidth key={Math.random()} />
    </div>
  )
}
